<template>
    <b-card no-body class="py-1">
        <b-overlay :show="getting" opacity="0.2">
            <div class="table-responsive px-1">
                <table class="table table-bordered table-sm align-middle">
                    <thead>
                        <tr>
                            <th class="w-20 text-nowrap">Kod</th>
                            <th class="w-20 text-nowrap">Cari</th>
                            <th class="w-20 text-nowrap">Dış Anlaşma Kodu</th>
                            <th class="w-20 text-nowrap">Dış Anlaşma Adı</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-nowrap">{{agreement.CariCode || '-' }}</td>
                            <td class="text-nowrap">{{agreement.CariName || '-' }}</td>
                            <td class="text-nowrap">{{agreement.AgreementCode || '-' }}</td>
                            <td class="text-nowrap">{{agreement.AgreementName || '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive mb-0">
                <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th class="text-nowrap">Sıra</th>
                            <th class="text-nowrap">Başlangıç Bölgesi</th>
                            <th class="text-nowrap">Bitiş Bölgesi</th>
                            <th class="text-nowrap">Araç Tipi</th>
                            <th class="text-nowrap">Stok Kodu</th>
                            <th class="text-nowrap">Ürün Tipi</th>
                            <th class="text-nowrap">Taşıma Tipi</th>
                            <th class="text-nowrap" v-for="c in columns" :key="c">{{ c }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(d,i) in agreement.Histories">
                            <tr :key="i">
                                <td class="text-nowrap text-center font-weight-bolder p-0">{{ i+1 }}</td>
                                <td class="text-nowrap">{{ $safe(d,'StartRegion') }}</td>
                                <td class="text-nowrap">{{ $safe(d,'EndRegion') }}</td>
                                <td class="text-nowrap">{{ $safe(d,'VehicleType') }}</td>
                                <td class="text-nowrap">{{ $safe(d,'StockCode') }}</td>
                                <td class="text-nowrap">{{ $safe(d,'StockName') }}</td>
                                <td class="text-nowrap">{{ $safe(d,'Status') }}</td>
                                <td v-for="c in columns" :key="c" :class="getValue(d,c) == null ? 'bg-light' : null">
                                    {{ getValue(d,c) }}
                                </td>
                            </tr>
                        </template>
                        <tr v-if="(agreement.Histories || []).length == 0">
                            <td class="text-center" colspan="1000">Henüz bir veri bulunmamaktadır.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import { AgreementApi } from '@/api/agreement';
import { BCard, BOverlay } from 'bootstrap-vue';
export default {
    components:{
        BCard,
        BOverlay
    },
    data(){
        return {
            agreement:[],
            getting:true
        }
    },
    mounted(){
        this.getHistory()
    },
    methods:{
        getHistory(){
            const id = this.$route.params.id
            AgreementApi.PriceChangeHistory(id).then(res => {
                if(res.data.Success){
                    this.agreement = res.data.Data
                }else{
                    this.$result(res)
                }
                this.getting = false
            }).catch(err => {
                this.$result(null,err)
                this.getting = false
            })
        },
        getValue(d,c){
            return (this.$safe(d,'DateOfPrices') || []).find(p => p.Key == c)?.Value || null
        }   
    },
    computed:{
        columns(){
            return (this.agreement.Histories || []).length > 0 ? this.agreement.Histories[0].DateOfPrices.map(d => d.Key) : []
        }
    }
}
</script>